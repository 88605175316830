import "./App.css";
import { Suspense, useState, lazy } from "react";
import LocaleContext from "./LocaleContext";
import Loading from "./components/loading";
import i18n from "./i18n";
import HamburgerMenu from "./components/HamburgerMenu";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";

const Home = lazy(() => import("./components/page"));
const About = lazy(() => import("./components/pages/About"));
const References = lazy(() => import("./components/pages/References"));
const Services = lazy(() => import("./components/pages/Services"));
const Contact = lazy(() => import("./components/pages/Contact"));
const Impressum = lazy(() => import("./components/pages/Impressum"));
const Privacy = lazy(() => import("./components/pages/Privacy"));

function App() {
    const [bool, setBool] = useState(false);
    const [locale, setLocale] = useState(i18n.language);
    i18n.on("languageChanged", (lng) => {
        setLocale(i18n.language);
        setBool(!bool);
    });

    return (
        <LocaleContext.Provider value={{ locale, setLocale }}>
            <Suspense fallback={<Loading />}>
                <BrowserRouter>
                    <ScrollToTop />
                    <HamburgerMenu />
                    <Routes>
                        <Route index element={<Home />} />
                        <Route path="/team" element={<About />} />
                        <Route path="/referenzen" element={<References />} />
                        <Route path="/dienstleistungen" element={<Services />} />
                        <Route path="/kontakt" element={<Contact />} />
                        <Route path="/impressum" element={<Impressum />} />
                        <Route path="/privacy" element={<Privacy />} />
                    </Routes>
                </BrowserRouter>
            </Suspense>
        </LocaleContext.Provider>
    );
}

export default App;
